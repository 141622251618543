import app from 'firebase/app';
import 'firebase/auth';

const devconfig = {
  apiKey: process.env.REACT_APP_API_KEY_DEV,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
  databaseURL: process.env.REACT_APP_DATABASE_URL_DEV,
  projectId: process.env.REACT_APP_PROJECT_ID_DEV,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
};

const prodconfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const config =
  process.env.REACT_APP_FIREBASE_CONFIG === "dev" ? devconfig : prodconfig;

 
class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }

    this.auth = app.auth();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.googleProvider.addScope(
      "https://www.googleapis.com/auth/contacts.readonly"
    );
  }

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    this.auth.signOut();
  };

  getCustomClaims = () => this.auth.currentUser.getIdTokenResult();

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);
}
 
export default Firebase;