export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const SIGN_IN_ADMIN = '/signin/admin';
export const DASHBOARD = '/dashboard';
export const CLIENT_PORFILE ='/clientprofile';
export const TRANSACTION_LIST = '/transactionlist';
export const INVALID = "/invalid";
export const FUNCTIONAL_BASE_URL =
  process.env.REACT_APP_FIREBASE_CONFIG === "dev"
    ? "https://asia-east2-dip-dev-882b0.cloudfunctions.net"
    : "https://asia-east2-dip-nonprod.cloudfunctions.net";